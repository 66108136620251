<template>
  <div class="page-wrapper container">
    <h2 class="page-title" data-aos="fade-up">Mini Cultivist</h2>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <MsgEntityCollectionEmpty v-if="!loadingMiniCultivists && !miniCultivists.length" name="Mini Cultivist Events" />

    <div class="content-wrp">
      <div class="row-considering-mobile">
        <MiniCultivistsPageItem
          v-for="mc in miniCultivists"
          :key="mc.id"
          class="col-12 col-md-6 col-lg-4"
          data-aos="fade-up"
          :item="mc"
        />
      </div>
      <infinite-loading :identifier="infiniteLoaderId" @infinite="loadMore" />
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import MiniCultivistDataFilterHelper from '@/helpers/data-filter/MiniCultivistDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import miniCultivistListQuery from '@/graphql/miniCultivist/MiniCultivistList.query.gql';
import miniCultivistsPageFilterQuery from '@/graphql/miniCultivist/MiniCultivistPageFilter.query.gql';

import MiniCultivistsPageItem from '@/components/partials/MiniCultivistsPageItem';
import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';

const DATA_PER_PAGE = 9;

export default {
  name: 'MiniCultivistsPage',
  components: {
    MsgEntityCollectionEmpty,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    MiniCultivistsPageItem,
  },
  metaInfo: {
    title: 'Mini Cultivist',
  },
  data() {
    return {
      busy: false,
      limit: 9,
      mainPageData: [],
      miniCultivistsType: null,
      genresType: null,
      eventsCategory: null,
      isOpen: false,
      hideFilter: false,
      emptyData: false,

      description: '',
      miniCultivists: [],
      loadingMiniCultivists: true,
      getMoreMiniCultivists: false,
      page: 1,
      infiniteLoaderId: +new Date(),
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
    };
  },
  computed: {
    filterQueryVariables() {
      return MiniCultivistDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
  },
  created() {
    this.filterValues = MiniCultivistDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.getFilterData();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = MiniCultivistDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.mini_cultivist,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
  },
  methods: {
    getFilterData() {
      return this.$apollo
        .query({
          query: miniCultivistsPageFilterQuery,
          fetchPolicy: 'no-cache',
        })
        .then(({ data = {} }) => {
          this.filterGroups = MiniCultivistDataFilterHelper.prepareFilterGroups(data);
        });
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = MiniCultivistDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMore(loadingState) {
      this.loadingMiniCultivists = true;
      this.$apollo
        .query({
          query: miniCultivistListQuery,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            page: this.page,
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          const { miniCultivistList } = data;

          this.miniCultivists.push(...miniCultivistList.data);
          this.getMoreMiniCultivists = miniCultivistList.hasMorePages;
          this.loadingMiniCultivists = false;

          if (!this.getMoreMiniCultivists) {
            loadingState.complete();
            return;
          }
          this.page++;
          loadingState.loaded();
        })
        .catch(() => {
          this.loadingMiniCultivists = false;
          loadingState.complete();
        });
    },
    resetFetchDataOptions() {
      this.loadingMiniCultivists = true;
      this.page = 1;
      this.miniCultivists = [];
      this.infiniteLoaderId += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;
}
.page-desc {
  max-width: 550px;
}
.content-wrp {
  min-height: 300px;
}
</style>
