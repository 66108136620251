import DataFilterHelper from './DataFilterHelper';
import { FILTER_TYPE_CATEGORIES, FILTER_TYPE_TYPES } from '@/helpers/data-filter/DataFilterTypes';

class MiniCultivistDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_CATEGORIES]: [],
    [FILTER_TYPE_TYPES]: [],
  };
  validValuesTypes = {
    array: [FILTER_TYPE_CATEGORIES, FILTER_TYPE_TYPES],
  };

  /**
   * @param {Object} groupsWithItems
   * @param {FilterGroupItem[]|null} groupsWithItems.categories
   * @param {FilterGroupItem[]|null} groupsWithItems.types
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups(groupsWithItems) {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addDefaultGroupItems({
        group: groupsWithItems[FILTER_TYPE_CATEGORIES],
        itemsType: FILTER_TYPE_CATEGORIES,
      }),
      this.addDefaultGroupItems({
        group: groupsWithItems[FILTER_TYPE_TYPES],
        itemsType: FILTER_TYPE_TYPES,
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);

    return {
      filters: {
        category: transformed[FILTER_TYPE_CATEGORIES],
        miniCultivistType: transformed[FILTER_TYPE_TYPES],
      },
    };
  }
}

export default MiniCultivistDataFilterHelper.create();
