<template>
  <div class="single-item-wrapper mb-5 position-relative">
    <RouterLink :to="routeTo">
      <div class="item-image mb-3" :style="`background-image:url(${mediaUrl})`" />
      <div class="title">{{ item.title }}</div>

      <template v-if="isEventType">
        <div v-if="item.destination" class="d-flex mt-1">
          <div v-if="item.destination.title" class="small-grey">{{ item.destination.title }}</div>
          <div v-if="item.category" class="small-grey pl-1">| {{ item.category }}</div>
        </div>
        <div v-if="startDate" class="small-grey">{{ startDate }}</div>
      </template>

      <div v-else class="d-flex flex-column">
        <div v-if="item.type" class="small-grey text-capitalize mt-1">{{ item.type }}</div>
        <div v-if="startDate" class="small-grey">{{ startDate }}</div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import ImageHandler from '@/helpers/ImageHandler';
import { getEntityRoute } from '@/helpers/entityType';
import { formatDate } from '@/helpers/dateTimeHelper';

export default {
  name: 'MiniCultivistsPageItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isEventType() {
      return this.item.type === 'Event';
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    routeTo() {
      return this.item.slug ? getEntityRoute(this.item) : null;
    },
    startDate() {
      if (!this.isEventType) {
        return formatDate(this.item.startPublishingAt);
      }

      let startDate = this.item.date && this.item.date[0] ? this.item.date[0].start_date : this.item.start_date;

      return formatDate(startDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-image {
  height: 171px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #d9d9d9;
  transition: all 0.3s linear;

  @media (min-width: 768px) {
    height: 250px;
  }
}

.single-item-wrapper {
  &:hover {
    cursor: pointer;

    .item-image {
      transform: scale(0.97);
    }
  }

  .title {
    display: block;
    color: #353535;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0.85px;
    text-transform: uppercase;
    word-break: break-word;
    line-height: 22px;
  }
}
</style>
